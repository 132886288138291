jQuery(document).ready(function($) {

    // Main Nav

    $('.hamburger').on('click', function(){
        $(this).toggleClass('is-active');
        $('.main-header-nav').slideToggle();
    });

    // Testimonial Slider

    $("#testimonial-slider").slick({
        dots: false,
        infinite: true,
        speed: 600,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        arrows: false
    });
});

var FCSD = FCSD || {};

window.addEventListener("DOMContentLoaded", function(){
    FCSD.selectOption = function(el, val){
        var opts = el.options;
        for (var opt, j = 0; opt = opts[j]; j++) {
            if (opt.value == val) {
                el.selectedIndex = j;
                break;
            }
        }
        el.dispatchEvent(new Event('change'));
    }

    FCSD.getURLParameter = function(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    FCSD.aryMap = function(coll, cb) {
        return Array.prototype.map.call(coll, cb);
    }

    FCSD.aryEach = function(coll, cb) {
        return Array.prototype.forEach.call(coll, cb);
    }

    FCSD.viewport = function(){
        var currentWidth;
        var xs = document.querySelector(".media-size-xs"),
        sm = document.querySelector(".media-size-sm"),
        md = document.querySelector(".media-size-md"),
        lg = document.querySelector(".media-size-lg"),
        xl = document.querySelector(".media-size-xl");

        if (xs.offsetWidth > 0 || xs.offsetHeight > 0) {
            currentWidth = "xs";
        }
        else if (sm.offsetWidth > 0 || sm.offsetHeight > 0) {
            currentWidth = "sm";
        }
        else if (md.offsetWidth > 0 || md.offsetHeight > 0) {
            currentWidth = "md";
        }
        else if (lg.offsetWidth > 0 || lg.offsetHeight > 0) {
            currentWidth = "lg";
        }
        else if (xl.offsetWidth > 0 || xl.offsetHeight > 0) {
            currentWidth = "xl";
        }
        return currentWidth;
    };

    FCSD.getCookie = function(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
});

window.addEventListener("resize", FCSD.viewport, false);
